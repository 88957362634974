<!-- 个人报告 -->
<template>
    <div>
        <div class="stu_report flex-column-center" v-if="showReport">
            <div class="title">学生个人报告</div>
            <div class="className">{{className}}</div>
            <p>截止目前，本轮后测中，共计收到本班{{stuCount}}位同学提交的{{pageCount}}份试卷。以下是本班参与书写后期测试的学生名单：</p>
            <div class="allStu">
                <p>（按姓名音序排列）</p>
                <table border="1">
                    <tr v-for="(item,index) in stuList" :key="index">
                        <td :class="{'actived':currentUser==items.userId}" v-for="items in item" :key="items.userId"
                            @click="personRepost(items.userId)">{{items.userName}}
                        </td>
                    </tr>
                </table>
                <p>请点击姓名查看学生个人报告：</p>
            </div>
            <div class="stuItem" id="pdfDom">
                <div class="stuTitle flex-align-center">【{{currentInfo.userInfo.userName}}】汉字识写能力报告
                    <el-button type="primary" @click="outPutPdfFn" icon="el-icon-download">下载个人报告</el-button>
                </div>
                <span style="font-weight:bold">个人信息：</span>
                <table border="1">
                    <tr>
                        <td>姓名</td>
                        <td>{{currentInfo.userInfo.userName}}</td>
                    </tr>
                    <tr>
                        <td>班级</td>
                        <td>{{className}}</td>
                    </tr>
                    <tr>
                        <td>ID</td>
                        <td>{{currentInfo.userInfo.userId}}</td>
                    </tr>
                    <tr>
                        <td>测评时间</td>
                        <td>{{dayjs(currentInfo.createTime).format("YYYY-MM-DD HH:mm")}}</td>
                    </tr>
                    <tr>
                        <td>试卷回收</td>
                        <td>共{{currentInfo.papers}}页</td>
                    </tr>
                </table>
                <span>根据该生后测调查内容，分析如下：</span>
                <div class="part_one item">
                    <div class="title ">1、书写习惯</div>
                    <p>掌握合理的坐姿和握笔姿势，是写好汉字的基础。本学生选择，结果如下：</p>
                    <p class="p_title">（1）握笔姿势</p>
                    <img src="../../assets/report/pen1.png">
                    <p>
                        <span v-if="currentInfo.woziSelecteds.length==0">该学生本题未选择</span>
                        <span v-else>该学生选择的结果是 <span v-for="(item,index) in currentInfo.woziSelecteds"
                                :key="index">握姿{{item}}</span></span>
                        。正确握笔方式是，应采用三指执笔法。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔，可以自行检查保持良好的握笔姿势。
                        可以学一学首都师范大学专家总结的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                    <div class="music flex-column-center">
                        <img src="../../assets/report/posture.jpg">
                        <span>龙须并排握在前，龙角分开在两边。</span>
                        <span>中间一只大龙眼，龙肚一定留空间。</span>
                        <span style="margin-top:20px">
                            （注：握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦。握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势。握姿3，右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。
                            握姿4中手腕内钩，笔向自己，会造成书写角度的错误，同时这种执笔方法会影响脊骨。）
                        </span>
                    </div>
                    <p class="p_title">（2）坐姿情况</p>
                    <img src="../../assets/report/sit.png">
                    <p>
                        <span v-if="currentInfo.zuoziSelecteds==0">该学生本题未选择</span>
                        <span v-else>该学生在日常坐姿选择了<span v-for="(item,index) in currentInfo.zuoziSelecteds"
                                :key="index">坐姿{{item}}</span></span>
                        。正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    <p>（注；坐姿1~3：这几类坐姿会对脊椎和视力造成很大影响。需要予以纠正。坐姿4，该坐姿是正确的，请继续保持。）</p>
                </div>
                <div class="part_three item">
                    <div class="title ">2、书写基础</div>
                    <p>学生写字我们分为2个场景考察，“日常书写”与“刻意书写”两方面。</p>
                    <div class="title ">（1）日常书写</div>
                    <p>通过第二、三、四题进行日常书写考察：从例字相似性角度，
                        该生在日常书写中例字相似性平均水平达到{{(currentInfo.userChart.dimension_f_4.result/currentInfo.userChart.dimension_f_4.count).toFixed(2)}}%，
                        {{(currentInfo.userChart.dimension_f_4.result/currentInfo.userChart.dimension_f_4.count).toFixed(2)>78?'高':'低'}}于全国常模{{gradeInfo[currentGradeName].normDefault}}%；
                        占格一致性平均值{{(currentInfo.userChart.dimension_f_5.result/currentInfo.userChart.dimension_f_5.count).toFixed(2)}}%，
                        {{(currentInfo.userChart.dimension_f_5.result/currentInfo.userChart.dimension_f_5.count).toFixed(2)>76?'高':'低'}}于全国常模{{gradeInfo[currentGradeName].beautifulDefault}}%
                    </p>
                    <p v-if="currentGradeName=='一年级'">（注：第二大题为补充笔画题，因此例字相似性和占格一致性值相对整体偏高）。</p>
                    <table border="1">
                        <tr>
                            <th>题目</th>
                            <th>生字</th>
                            <th>例字相似性</th>
                            <th>占格一致性</th>
                            <th>{{currentGradeName=='一年级'||currentGradeName=='二年级'?'1~2':currentGradeName=='三年级'||currentGradeName=='四年级'?'3~4':'5~6'}}例字相似性全国常模
                            </th>
                            <th>{{currentGradeName=='一年级'||currentGradeName=='二年级'?'1~2':currentGradeName=='三年级'||currentGradeName=='四年级'?'3~4':'5~6'}}占格一致性全国常模
                            </th>
                        </tr>
                        <tr>
                            <td>第二题</td>
                            <td>{{gradeInfo[currentGradeName].questions[0]}}</td>
                            <td>{{(currentInfo.userChart.dimension_d_1.result/currentInfo.userChart.dimension_d_1.count).toFixed(2)}}%
                            </td>
                            <td>{{(currentInfo.userChart.dimension_e_1.result/currentInfo.userChart.dimension_e_1.count).toFixed(2)}}%
                            </td>
                            <td rowspan="4">{{gradeInfo[currentGradeName].normDefault}}%</td>
                            <td rowspan="4">{{gradeInfo[currentGradeName].beautifulDefault}}%</td>
                        </tr>
                        <tr>
                            <td>第三题</td>
                            <td style="width:200px">{{gradeInfo[currentGradeName].questions[1]}}</td>
                            <td>{{(currentInfo.userChart.dimension_d_2.result/currentInfo.userChart.dimension_d_2.count).toFixed(2)}}%
                            </td>
                            <td>{{(currentInfo.userChart.dimension_e_2.result/currentInfo.userChart.dimension_e_2.count).toFixed(2)}}%
                            </td>
                        </tr>
                        <tr>
                            <td>第四题</td>
                            <td>{{gradeInfo[currentGradeName].questions[2]}}</td>
                            <td>{{(currentInfo.userChart.dimension_d_3.result/currentInfo.userChart.dimension_d_3.count).toFixed(2)}}%
                            </td>
                            <td>{{(currentInfo.userChart.dimension_e_3.result/currentInfo.userChart.dimension_e_3.count).toFixed(2)}}%
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">日常书写平均水平</td>
                            <td>{{(currentInfo.userChart.dimension_f_4.result/currentInfo.userChart.dimension_f_4.count).toFixed(2)}}%
                            </td>
                            <td>{{(currentInfo.userChart.dimension_f_5.result/currentInfo.userChart.dimension_f_5.count).toFixed(2)}}%
                            </td>
                        </tr>
                    </table>
                    <div class="title">（2）刻意书写</div>
                    <p>通过第五题对书写内容采用整体测评方式对内容的的作品完整度、占格一致性、例字相似性、布局整齐度四个维度进行分析。</p>
                    <table border="1">
                        <tr>
                            <th>评价维度</th>
                            <th>平均分值</th>
                            <th>{{currentGradeName=='一年级'||currentGradeName=='二年级'?'1~2':currentGradeName=='三年级'||currentGradeName=='四年级'?'3~4':'5~6'}}全国常模
                            </th>
                        </tr>
                        <tr>
                            <td>占格一致性</td>
                            <td>{{(currentInfo.userChart.dimension_e_4.result/currentInfo.userChart.dimension_e_4.count).toFixed(2)}}%
                            </td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[1]}}%</td>
                        </tr>
                        <tr>
                            <td>作品完整度</td>
                            <td>{{(currentInfo.userChart.dimension_c_4.result*100/currentInfo.userChart.dimension_c_4.count).toFixed(2)}}%
                            </td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[0]}}%</td>
                        </tr>
                        <tr>
                            <td>例字相似性</td>
                            <td>{{(currentInfo.userChart.dimension_d_4.result/currentInfo.userChart.dimension_d_4.count).toFixed(2)}}%
                            </td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[2]}}%</td>
                        </tr>
                        <tr>
                            <td>布局整齐度</td>
                            <td>{{(currentInfo.userChart.dimension_f_1.result/currentInfo.userChart.dimension_f_1.count).toFixed(2)}}%
                            </td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[3]}}%</td>
                        </tr>
                    </table>
                    <div class="myChart item" id="myChart_stu"></div>
                    <p>该生的刻意书写练习作品评价结果如下：</p>
                    <p>1.整体水平为{{level}}等级。</p>
                    <p>2.作品完整度为{{(currentInfo.userChart.dimension_c_4.result*100/currentInfo.userChart.dimension_c_4.count).toFixed(2)}}%
                        ，篇章完整，内容正确。</p>
                    <p>3.卷面布局整齐度为{{(currentInfo.userChart.dimension_f_1.result/currentInfo.userChart.dimension_f_1.count).toFixed(2)}}%
                        ，布局基本合理，注意重心位置。</p>
                    <p>4.占格一致性{{(currentInfo.userChart.dimension_e_4.result/currentInfo.userChart.dimension_e_4.count).toFixed(2)}}%
                        ，字的大小适中，书写流畅。</p>
                    <p>5.例字相似性为{{(currentInfo.userChart.dimension_d_4.result/currentInfo.userChart.dimension_d_4.count).toFixed(2)}}%
                        ，书写基本规范，结构需严谨。</p>
                    <div class="title ">（3）两种书写场景效果对比</div>
                    <p>通过对日常书写与刻意书写的内容，从书写例字相似性，占格一致性角度对比情况如下：</p>
                    <table border="1">
                        <tr>
                            <th>书写场景</th>
                            <th>例字相似性</th>
                            <th>占格一致性</th>
                        </tr>
                        <tr>
                            <td>日常书写</td>
                            <td>{{(currentInfo.userChart.dimension_f_4.result/currentInfo.userChart.dimension_f_4.count).toFixed(2)}}%
                            </td>
                            <td>{{(currentInfo.userChart.dimension_f_5.result/currentInfo.userChart.dimension_f_5.count).toFixed(2)}}%
                            </td>
                        </tr>
                        <tr>
                            <td>刻意书写</td>
                            <td>{{(currentInfo.userChart.dimension_d_4.result/currentInfo.userChart.dimension_d_4.count).toFixed(2)}}%
                            </td>
                            <td>{{(currentInfo.userChart.dimension_e_4.result/currentInfo.userChart.dimension_e_4.count).toFixed(2)}}%
                            </td>
                        </tr>
                    </table>
                    <p>由此可见：</p>
                    <p>1）例字相似性角度，作品刻意书写成绩
                        {{(currentInfo.userChart.dimension_d_4.result/currentInfo.userChart.dimension_d_4.count).toFixed(2)>(currentInfo.userChart.dimension_f_4.result/currentInfo.userChart.dimension_f_4.count).toFixed(2)?'高于':'低于'}}日常书写；
                        {{comments.like[comparison((currentInfo.userChart.dimension_f_4.result/currentInfo.userChart.dimension_f_4.count).toFixed(2),(currentInfo.userChart.dimension_d_4.result/currentInfo.userChart.dimension_d_4.count).toFixed(2))]}}
                    </p>
                    <p>2）占格一致性角度，作品刻意书写成绩
                        {{(currentInfo.userChart.dimension_e_4.result/currentInfo.userChart.dimension_e_4.count).toFixed(2)>(currentInfo.userChart.dimension_f_5.result/currentInfo.userChart.dimension_f_5.count).toFixed(2)?'高于':'低于'}}日常书写；
                        {{comments.consistency[comparison((currentInfo.userChart.dimension_f_5.result/currentInfo.userChart.dimension_f_5.count).toFixed(2),(currentInfo.userChart.dimension_e_4.result/currentInfo.userChart.dimension_e_4.count).toFixed(2))]}}
                    </p>
                    <p>（*注：报告中NaN%值表示数据为空。以下情况可能导致无法检测到数据：试卷未作答、未粘贴条码、试卷涂鸦、损坏或缺失的情况。请悉知。）</p>
                    <!-- <div class="title">学生作品：</div>
                    <img :src="item" v-for="(item,index) in currentInfo.files" :key="index"> -->
                </div>
                <div class="part_two item">
                    <div class="title ">3、书写辨析</div>
                    <div class="title ">（1）基础识写与应用 </div>
                    <p>第二题为基础识写题，主要考察学生根据拼音和生字的轮廓、语义语境等认识生字的能力。测评发现，该生书写的正确率为
                        {{(currentInfo.userChart.dimension_c_1.result*100/currentInfo.userChart.dimension_c_1.count).toFixed(2)}}%，
                        本年级平均正确率为{{(currentInfo.gradeChart.dimension_c_1.result*100/currentInfo.gradeChart.dimension_c_1.count).toFixed(2)}}%。
                    </p>
                    <div class="title ">（2）部件与结构认知</div>
                    <p>第三题为部件与结构题，通过“偏旁部首组合生字”，可以考察学生对基本部件的认识，及所整组合生字的认识。能够考察学生对常用偏旁位置及组合规律的认识。
                        测评发现，该生正确率{{(currentInfo.userChart.dimension_c_2.result*100/currentInfo.userChart.dimension_c_2.count).toFixed(2)}}%，
                        本年级平均正确率为{{(currentInfo.gradeChart.dimension_c_2.result*100/currentInfo.gradeChart.dimension_c_2.count).toFixed(2)}}%。
                    </p>
                    <div class="title ">（3）笔顺笔画掌握</div>
                    <p>笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。通过笔顺书写易错字的考察，
                        该生正确率{{(currentInfo.userChart.dimension_c_3.result*100/currentInfo.userChart.dimension_c_3.count).toFixed(2)}}%，
                        本年级平均正确率为{{(currentInfo.gradeChart.dimension_c_3.result*100/currentInfo.gradeChart.dimension_c_3.count).toFixed(2)}}%。
                        本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                </div>
            </div>
        </div>
        <template v-else>
            <el-empty style="margin-top:200px" description="暂无数据"></el-empty>
        </template>
    </div>

</template>
<script>
    import {
        getPdf
    } from '../../utils/exportPdf'

    export default {
        props: ['className', 'classId'],
        data() {
            return {
                stuList: [],
                stuCount: '',
                pageCount: '',
                currentInfo: {
                    userInfo: {
                        userName: ''
                    },
                    woziSelecteds: []
                },
                chartData: [],
                level: '',
                currentUser: '',
                gradeInfo: {
                    '一年级': {
                        normDefault: 78, // 例字相似性全国常模
                        beautifulDefault: 76, // 占格一致性全国常模
                        writeDefault: [86, 76, 78, 75], // 刻意书写全国常模,依次为 作品完整度、占格一致性、例字相似性、布局整齐度
                        questions: ['主、各、因、多', '思、晴、爸、居', '校、走、光、之']
                    },
                    '二年级': {
                        normDefault: 78,
                        beautifulDefault: 76,
                        writeDefault: [86, 76, 78, 75],
                        questions: ['礼、息、暖、菜', '邮、桃、启、背', '柳、甘、匹、与']
                    },
                    '三年级': {
                        normDefault: 83,
                        beautifulDefault: 79,
                        writeDefault: [90, 79, 83, 78],
                        questions: ['南、北、舟、守、兔、语', '减形、裂尘、闲匀、止尔', '痕、释、瓣、拂']
                    },
                    '四年级': {
                        normDefault: 83,
                        beautifulDefault: 79,
                        writeDefault: [90, 79, 83, 78],
                        questions: ['山、水、龙、楼、立、迟', '稀赖、茅率、疾序、末叉', '吼、杂、慰、蜓']
                    },
                    '五年级': {
                        normDefault: 85,
                        beautifulDefault: 82,
                        writeDefault: [92, 82, 85, 81],
                        questions: ['愉、榆、瓢、飘', '锻绷、桑鲁、尾勿、申斤', '插、疑、忌、兼']
                    },
                    '六年级': {
                        normDefault: 85,
                        beautifulDefault: 82,
                        writeDefault: [92, 82, 85, 81],
                        questions: ['俱、箭、悔、狂', '聊批、泰寞、匙魔、弗乏', '藏、御、毙、霉']
                    }
                },
                showReport: false,
                comments: {
                    consistency: [
                        "学生整体对于单字的书写大小适中，整体表现优异。建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体书写大小基本一致，整体看来稍有不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体字的书写大小偏差较大，分布不均匀，整体看来不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。"
                    ],
                    like: [
                        "学生整体书写工整，与例字相似性较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。",
                        "学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。",
                        "学生整体书写不太规范，与例字相似性较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。"
                    ]
                }
            }
        },
        computed: {
            diffName() {
                let num1 = Math.abs((this.currentInfo.userChart.dimension_f_4.result / this.currentInfo.userChart
                    .dimension_f_4.count).toFixed(2) - (this.currentInfo.userChart.dimension_d_4.result / this
                    .currentInfo.userChart.dimension_d_4.count).toFixed(2));
                let num2 = Math.abs((this.currentInfo.userChart.dimension_f_5.result / this.currentInfo.userChart
                    .dimension_f_5.count).toFixed(2) - (this.currentInfo.userChart.dimension_e_4.result / this
                    .currentInfo.userChart.dimension_e_4.count).toFixed(2));
                console.log(num1, num2)
                if (num1 > num2) {
                    return "例字相似性"
                } else {
                    return "占格一致性"
                }
            },
            currentGradeName() {
                return this.className.slice(0, 3)
            }
        },
        watch: {
            classId: {
                handler(val) {
                    console.log('classId改变:', val);
                    this.classReport()
                },
                immediate: true
            }
        },
        methods: {
            exportpdf() {
                getPdf(`【${this.currentInfo.userInfo.userName}】汉字识写能力报告`, 'pdfDom')
            },
            initChart() {
                console.log('初始化echarts')
                this.$echarts.init(document.getElementById("myChart_stu")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart_stu"));
                myChart.setOption({
                    tooltip: {
                        show: true
                    },
                    radar: {
                        indicator: [{
                                name: '作品完整度',
                                max: 100
                            },
                            {
                                name: '例字相似性',
                                max: 100
                            },
                            {
                                name: '占格一致性',
                                max: 100
                            },
                            {
                                name: '布局整齐度',
                                max: 100
                            }
                        ]
                    },
                    series: [{
                        symbolSize: 10,
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: 0.2,
                        },
                        lineStyle: {
                            width: 1,
                            color: "rgba(66, 242, 185, 1)",
                        },
                        type: 'radar',
                        data: [{
                            value: this.chartData,
                            name: '书写水平',
                            areaStyle: {
                                color: {
                                    type: "radial",
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.5,
                                    colorStops: [{
                                            offset: 0,
                                            color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 0.15,
                                            color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 0.75,
                                            color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                        }]
                    }]
                })
            },
            // 班级报告
            async classReport() {
                let data = {
                    classId: this.classId,
                    type: 3
                }
                let resData = await this.$Api.DataScreen.queryClassReport(data);
                console.log('班级报告:', resData);
                this.pageCount = resData.data.paperNumber;
                var chunk = 10;
                var len = resData.data.users.length;
                this.stuCount = len;
                var result = [];
                for (let i = 0; i < len; i += chunk) {
                    result.push(resData.data.users.slice(i, i + chunk)) // 每10项分成一组        
                }
                this.stuList = result;
                this.currentUser = result[0][0].userId;
                this.personRepost(result[0][0].userId)
            },
            // 个人报告
            async personRepost(userId) {
                let data = {
                    userId,
                    type: 3
                }
                this.currentUser = userId;
                let resData = await this.$Api.DataScreen.queryPersonReport(data);
                console.log('个人报告:', resData);
                this.showReport = true;
                this.currentInfo = resData.data;
                this.chartData = [(resData.data.userChart.dimension_c_4.result * 100 / resData.data.userChart
                        .dimension_c_4.count).toFixed(2),
                    (resData.data.userChart.dimension_d_4.result / resData.data.userChart.dimension_d_4
                        .count).toFixed(2),
                    (resData.data.userChart.dimension_e_4.result / resData.data.userChart.dimension_e_4
                        .count).toFixed(2),
                    (resData.data.userChart.dimension_f_1.result / resData.data.userChart.dimension_f_1
                        .count).toFixed(2)
                ]
                let num = (this.currentInfo.userChart.dimension_d_4.result / this.currentInfo.userChart
                    .dimension_d_4.count).toFixed(2);
                this.level = num > 80 ? '优秀' : num >= 65 && num < 80 ? '良好' : '待提升';
                setTimeout(() => {
                    this.initChart();
                }, 1000)
            },
            // 对比一致性与相似性
            comparison(val1, val2) {
                let result;
                if (val1 < val2) {
                    result = val1
                } else {
                    result = val2
                }
                console.log('result:', result);
                if (result >= 80) {
                    return 0
                } else if (result >= 65 && result < 80) {
                    return 1
                } else {
                    return 2
                }
            },
            outPutPdfFn() {
                let vm = this;
                const A4_WIDTH = 592.28;
                const A4_HEIGHT = 841.89;
                // $myLoading 自定义等待动画组件，实现导出事件的异步等待交互
                // this.$myLoading('正在导出pdf，请稍候。。。', true);
                vm.$nextTick(() => {
                    // dom的id。
                    let target = document.getElementById('pdfDom');
                    let pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT;
                    // 获取分割dom，此处为class类名为item的dom
                    let lableListID = document.getElementsByClassName('item');
                    // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
                    for (let i = 0; i < lableListID.length; i++) {
                        let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) /
                            pageHeight);
                        if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                            let divParent = lableListID[i].parentNode; // 获取该div的父节点
                            let newNode = document.createElement('div');
                            newNode.className = 'emptyDiv';
                            newNode.style.background = '#ffffff';
                            let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i]
                                .offsetHeight);
                            newNode.style.height = _H + 30 + 'px';
                            newNode.style.width = '100%';
                            let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
                            // 判断兄弟节点是否存在
                            console.log(next);
                            if (next) {
                                // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                                divParent.insertBefore(newNode, next);
                            } else {
                                // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                                divParent.appendChild(newNode);
                            }
                        }
                    }
                    this.exportpdf()
                });
            },
            isSplit(nodes, index, pageHeight) {
                // 计算当前这块dom是否跨越了a4大小，以此分割
                if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index +
                        1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
                    return true;
                }
                return false;
            },
        },
        mounted() {},
    };
</script>
<style lang='less' scoped>
    @import url("./less/report_stu.less");
</style>